.card-container{
  display: flex;
  justify-content: center;
  gap: 2rem;
  transition: transform 0.8s ease;
}

.home-carousel {
transition: transform 0.8s ease;
}

/* When moving the carousel left-to-right */
.home-carousel.move-left {
transform: translateX(-100%);
}

/* When moving the carousel right-to-left */
.home-carousel.move-right {
transform: translateX(100%);
}

@media (max-width: 768px) {
  // .home-card {
  //   width: 100% !important
  // }
  .card-container{
    display: flex !important;
    justify-content: center;
    gap: 1rem !important;
  }
}

@media (max-width: 530px) {
  // .home-card {
  //   width: 100% !important
  // }
  .home-carousel{
    width: 80% !important;
  }
}

@media (max-width: 380px) {
  // .home-card {
  //   width: 100% !important
  // }
  .card-container{
    width: 100%;
  }
}

@media (min-width: 768px) {
  // .home-card {
  //   width: 40% !important;
  // }
}

@media (min-width: 1024px) {
  // .home-card {
  //   width: 30% !important;
  // }
}



.home-card {
  width: 100%;
  height: 100%;
  margin-block: 1rem;
  border-radius: 10px !important;
  box-shadow: 0px 10px 15px 0px rgba(135, 135, 135, 0.296); 
  transition: box-shadow 0.3s ease; 
}

.home-card:hover {
    box-shadow: 0px 20px 25px 0px rgba(135, 135, 135, 0.5); 
}

.home-card:hover .card-container{
  gap: 0 !important;
}

.css-ktslay-MuiButtonBase-root-MuiIconButton-root{
  background-color: transparent !important;
  border: 1.5px solid #70513B !important;
  margin-top: -3rem !important;
}
.css-ktslay-MuiButtonBase-root-MuiIconButton-root >   .css-i4bv87-MuiSvgIcon-root{
  color:#70513B !important
}

.css-bbreus-MuiButtonBase-root-MuiIconButton-root{
  color: rgba(92, 131, 191, 1) !important;
}
.css-8ucetn-MuiButtonBase-root-MuiIconButton-root {
  color: rgba(76, 100, 129, 0.36) !important;
}


.home-cardcontent{
  display: flex;
  align-items: center;
  gap: 5px;
}

@media (max-width: 1300px) {
  .home-card {
    gap: 2rem !important;
  }
}

.card-smheader{
  margin: 0 !important;
  cursor: pointer;
}
