.therapist-image {
    border-radius: 160px;
    border: 2px solid #5C83BF;
    width: 100px;
    height: 100px; 
    object-fit: cover;
}

.profile_text {
    max-width: 600px;
    word-wrap: break-word;
}

.therapist-info_card {
    background-color: #ffffff;
    border-radius: 10px !important;
    border: 1px solid #E3DBD5;
    box-shadow: 0px 31px 42px 0px #E9E2DD !important; 
    padding: 30px;
    width: 100%;
}

.info-item::before {
    content: '';
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    width: 7px;
    height: 7px;
    background-color: #4C6EA2;
    border-radius: 50%;
}

.custom-badge {
    background: #5C83BF1A;
    border: 2px solid #4C6EA2;
    font-size: 15px;
    font-weight: 700;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-flex; 
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    padding: 10px;
}
  