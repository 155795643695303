.home_footer {
    height: 150px;
    margin-top: 100px;
    align-items: center;
    background-repeat: no-repeat;
    justify-content: space-between;
    display: flex;
    @media (max-width: 800px) {
        align-items: center;
        gap: 1rem;
        flex-direction: row !important;    
        height: 180px;
    }
    @media (max-width: 476px) {
        margin-bottom: 2rem !important;
        font-size: 14px;
    }
    @media (min-width: 2440px) {
        background-size: 100% 100%;
    }
    padding-left: 24px;
    padding-right: 24px;
}

.animated-text-home {
    text-decoration: none;
    background: linear-gradient(to right, #4C6EA2 50%, #f68484 50%, #fa3f77);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: background-position 0.3s ease-out; /* Added 's' after 0.5 for seconds */
}

.animated-text-home:hover {
    background-position: -100%;
}
