.record-icon {
    fill: rgba(132, 238, 149, 1) !important;
    }
    @keyframes beat {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.heart-container {
    display: inline-block;
    animation: beat 1s infinite;
}

.heart-icon {
    color: rgba(132, 238, 149, 1);
    width: 24px !important;
    height: 24px !important;
}

.vertical-line1{
    height: 2rem;
    width: 1px;
    margin: 0 10px;
    background-color: rgba(227, 219, 213, 1);
}

.webcam-visible {
    border-radius: 100%;
    width: 205px;
    height: 205px;
    object-fit: cover;
}

.webcam-invisible {
    visibility: hidden;
}

@keyframes pulsate {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(92, 131, 191, 0.7);
    }
    50% {
      transform: scale(1.05);
      box-shadow: 0 0 0 10px rgba(92, 131, 191, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(92, 131, 191, 0);
    }
  }
  
  .topic-option {
    transition: all 0.3s ease;
  
    &:hover {
      transform: translateY(-4px);
    }
  
    @for $i from 0 through 3 {
      &-#{$i} {
        animation: pulsate 3.5s infinite #{$i * 0.5}s;
      }
    }
  }