.greeting-section{
  height: 72vh;
  background-image: url("../../data/HomeBackground.png");
  background-color: #FFF7F1;
  background-size: 101% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.session-button{
  cursor: pointer;
  width: 75px;
  &:hover {
    width: 78px;
  }
}

.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  background-color: #FFF7F1;
  position: relative;
}

.css-5xe99f-MuiLinearProgress-bar1{
  background-color: #3ec44b !important;
  border-radius: 5px;
}

.home-slide{
  background-color: #FFF7F1;
}

.home-circles{
  width: 512px;
  height: 512px;
  position: relative;
}

.circles-mic{
  position: relative;
  top: -290px;
  left: 75px;
  width: 75px;
  height: 75px;
  animation: rotateAroundCircle 20s linear infinite;
}

.circles-video{
  position: relative;
  top: -290px;
  left: 0px;
  width: 75px;
  height: 75px;
  animation: rotateAroundCircle 20s linear infinite;
  animation-delay: -6.5s;
}

.circles-articles{
  position: relative;
  top: -290px;
  left: -75px;
  width: 75px;
  height: 75px;
  animation: rotateAroundCircle 20s linear infinite;
  animation-delay: -13.5s;
}

@keyframes rotateAroundCircle {
  from {
    transform: rotate(0deg) translateX(165px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(165px) rotate(-360deg);
  }
}

@media (max-width: 1000px) {
  @keyframes rotateAroundCircle {
    from {
      transform: rotate(0deg) translateX(145px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(145px) rotate(-360deg);
    }
  }
  .greeting-section{
    height: 40rem;
  }
  .home-circles{
    width: 450px;
    height: 450px;
  }
  .circles-mic{
    top: -251px;
    left: 60px;
    width: 60px;
    height: 60px;
  }
  .circles-video{
    top: -251px;
    left: 0px;
    width: 60px;
    height: 60px;
  }
  .circles-articles{
    top: -251px;
    left: -60px;
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 768px) {
  @keyframes rotateAroundCircle {
    from {
      transform: rotate(0deg) translateX(115px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(115px) rotate(-360deg);
    }
  }
  .home-circles{
    width: 360px;
    height: 360px;
  }
  .circles-mic{
    top: -202px;
    left: 50px;
    width: 50px;
    height: 50px;
  }
  .circles-video{
    top: -202px;
    left: 0px;
    width: 50px;
    height: 50px;
  }
  .circles-articles{
    top: -202px;
    left: -50px;
    width: 50px;
    height: 50px;
  }
  .card-container{
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  .greeting-part {
    align-items: center !important;
    text-align: center;
  }
}

@media (max-width: 600px) {
  @keyframes rotateAroundCircle {
    from {
      transform: rotate(0deg) translateX(125px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(125px) rotate(-360deg);
    }
  }
  .greeting-section{
    flex-direction: row !important;
    place-content: baseline;
    justify-content: center;
    height: 47rem;
  }
  .greeting-part{
    align-items: center !important;
    text-align: center;
    justify-content: center !important;
  }
  .home-circles{
    width: 400px;
    height: 400px;
    align-self: center;
  }
  .home-images{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .circles-mic{
    top: -226px;
    left: 60px;
    width: 60px;
    height: 60px;
  }
  .circles-video{
    top: -226px;
    left: 0px;
    width: 60px;
    height: 60px;
  }
  .circles-articles{
    top: -226px;
    left: -60px;
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 450px) {
  .greeting-section{
    height: 49rem;
  }
}

@media (max-width: 400px) {
  @keyframes rotateAroundCircle {
    from {
      transform: rotate(0deg) translateX(110px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(110px) rotate(-360deg);
    }
  }
  .home-circles{
    width: 340px;
    height: 340px;
    align-self: center;
  }
  .circles-mic{
    top: -192px;
    left: 50px;
    width: 50px;
    height: 50px;
  }
  .circles-video{
    top: -192px;
    left: 0px;
    width: 50px;
    height: 50px;
  }
  .circles-articles{
    top: -192px;
    left: -50px;
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 1000px) and (max-width: 1400px) {
  .greeting-section{
    height: 95vh;
  }
}

@media (min-width: 450px) and (max-width: 600px) {
  .greeting-section {
    height: 47rem;
    flex-direction: row !important;
    place-content: baseline;
    justify-content: center;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .greeting-section{
    height: 30rem;
  }
}

.svgicon-start:hover {
  box-shadow: 0px 15px 18px 0px rgba(36, 36, 36, 0.5);
}

/* New styles for the center button */
.center-button {
  position: absolute;
  top: 50%;
  left: 50%;
  // transform: translate(-10%, -50%);
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: #6186C0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  transition: all 0.3s ease;
}

.center-button:hover {
  background-color: #4C6EA2;
  transform: translate(-50%, -50%) scale(1.05);
}