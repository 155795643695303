/* CalendarStyle.scss */
.prevent_overflow {
  overflow-x: hidden;
  width: 100%;
}

.calendar-container {
  margin-top: 30px;
  padding: 20px;
  border-radius: 40px;
  transition: all 0.5s ease;
  box-shadow: 0 0 70px rgba(123, 152, 245, 0.36);
}

.month-year {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 0 10px;
}

.days-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  padding: 25px;
  color: #4C6481;

  @media (max-width: 600px) {
    gap: 6px;
    padding: 8px;
  }
}

.day {
  display: flex;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 7px rgba(125, 153, 245, 0.783);
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  padding: 20%;
  transition: all 0.5s ease;
  font-weight: bold;
  font-size: 17px;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
  }

  @media (max-width: 600px) {
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;

    // Remove hover effect on mobile
    &:hover {
      transform: none;
    }
  }
}

.current-day {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(221,227,244);
  }
  70% {
     box-shadow: 0 0 0 0px rgb(46, 120, 206);
  }
  100% {
     box-shadow: 0 0 0 8px rgba(0, 132, 255, 0);
  }
}

.moodrep-image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  img {
    max-width: 80%;
    max-height: 80%;
    object-fit: cover;
    border-radius: 50%;
  }
}

// Fade transition styles
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

// Item transition styles
.item-enter {
  opacity: 0;
  transform: scale(0.9);
}

.item-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}

.item-exit {
  opacity: 1;
  transform: scale(1);
}

.item-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}

// Modal styles
.modal-40w {
  max-width: 40%;
  margin: 1.75rem auto;

  @media (max-width: 992px) {
    max-width: 60%;
  }

  @media (max-width: 768px) {
    max-width: 80%;
  }

  @media (max-width: 500px) {
    max-width: 95%;
    margin: 1rem;
  }
}

.modal-content {
  border-radius: 10px;
  overflow: hidden;
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23005eb1%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e');
}

.carousel-control-next-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23005eb1%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
}

.carousel-indicators [data-bs-target] {
  background-color: #005eb1;
}

// Additional responsive styles
@media (max-width: 600px) {
  .content {
    padding: 0 8px;
  }

  .prevent_overflow {
    padding: 0;
  }

  // Adjust modal padding for mobile
  .modal-header {
    padding: 12px 16px;
  }

  .modal-body {
    padding: 16px;
  }

  // Adjust button sizes for mobile
  .btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}

// Small height screens
@media (max-height: 700px) {
  .days-container {
    padding: 15px;
  }

  .day {
    padding: 15%;
  }
}