.prevent_overflow {
  overflow-x: hidden;
  min-height: unset !important;
}

.home-slide {
  background-color: #FFF7F1;
  min-height: unset !important;
}

:root {
  background-color: #FFF7F1;
}

body {
  margin: 0;
  padding: 0;
  background-color: #FFF7F1;
  min-height: unset !important;
  height: auto !important;
}