.footer {
    background-image: url("../../data/footer-bg.png") ;
    height: 140px;
    // margin-top: 100px;
    align-items: center;
    background-repeat: no-repeat;
    justify-content: space-between;
    display: flex;
    @media (max-width: 800px) {
        align-items: center;
        gap: 1rem;
        flex-direction: row !important;    
        height: 180px;
    }
    @media (max-width: 476px) {
        // margin-bottom: 2rem !important;
        font-size: 14px;
    }
    @media (min-width: 2440px) {
        background-size: 100% 100%;
    }
    padding-left: 24px;
    padding-right: 24px;
}

.horizontal-line {
    height: 1px;
    width: 100%;
    margin: 5px 3px;
    margin-inline-start: auto;
}

.animated-text {
    text-decoration: none;
    background: linear-gradient(to right, #ffffff 50%, #ffb5b5 50%, #f47198);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: background-position 0.3s ease-out; /* Added 's' after 0.5 for seconds */
}

.animated-text:hover {
    background-position: -100%;
}
