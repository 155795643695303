$blue: #5C83BF;
$black: #000000;
$yellow: #EEC230;
$white: #ffffff;
$grey: grey;
$red: #aa4953;
$lightBlue: #2e78ce;
$lightYellow: rgba(255, 255, 0, 0.5);

%btn-style {
    padding: 10px 24px;
    border-radius: 100px;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}


.text-blue { color: $blue; }
.text-black { color: $black; }
.text-yellow { color: $yellow; }


.navbar_header_landing {
    position: relative;
    width: 100% !important;
    justify-content: center;
    margin: 0 auto;
    padding: 15px;
}

.navbar_header_landing {
    border: none;
    box-shadow: none;
}

.blue_btn {
    @extend %btn-style;
    background-color: $blue;
    border: 1px solid $blue !important;
    color: $white;

    &:hover {
        background-color: $white;
        color: $blue;
    }
}

.white_btn {
    @extend %btn-style;
    background-color: $white;
    border: 1px solid $blue;
    color: $blue;

    &:hover {
        background-color: $blue;
        color: $white;
    }
}

.red_btn {
    @extend %btn-style;
    background-color: $red;
    border: 1px solid $red !important;
    color: $white;

    &:hover {
        background-color: $white;
        color: $red;
    }
}

.blue_btn_no_hover {
    @extend %btn-style;
    background-color: $blue;
    border: 1px solid $blue !important;
    color: $white;
}

.disabled-btn,
.disabled-btn:hover{
    background-color: grey;
    border: 1px solid grey !important;
    cursor: not-allowed;
    color: #fff;
}

.signout-btn_mobile {
    border-style: none;
    text-align: end;
    background-color: transparent;
    color: $white;
    padding: 15px 10px;
}

.bigger_height {
    height: 50rem;
}

.smaller_height {
    height: 30rem;
}


.box {
    border: 0px;
    background-color: #fff;
    padding: 10px;
    margin: 4.5px;
    text-align: center;
    line-height: 130px;
    border-radius: 8px;
    flex: none;
    height: 50px;
    left: 477px;
    top: 77px;
    width: 21%;
}

.box.colored:nth-child(5) {
    background-color: #3CB371; /* Good Mood - Medium Sea Green */
}

.box.colored:nth-child(4) {
    background-color: #ACDF87; /* Above Average Mood - Crayola Yellow-Green */
}

.box.colored:nth-child(3) {
    background-color: #FFFF00; /* Neutral Mood - Bright Yellow */
}

.box.colored:nth-child(2) {
    background-color: #D4824E; /* Below Average Mood - Bright Orange */
}

.box.colored:nth-child(1) {
    background-color: #C16244; /* Bad Mood - Dark Orange */
}


.checkbox input[type="checkbox"] {
    display: none;
}

.checkbox-circle {
    width: fit-content;
    border-radius: 50%;
    width: 2.5rem;
    cursor: pointer;
    margin: 4.5px;
    height: 2.5rem;
    opacity: 0.2;
}

.checkbox-label {
    display: flex;
    cursor: pointer;
    flex-direction: row-reverse;
    align-items: center;
}

.checkbox input[type="checkbox"]:checked + .checkbox-label .checkbox-circle{
    opacity: 1;
}


.session {
    font-size: 1rem;
    color: rgb(173, 173, 173);
}

.overview_height {
    height: 100%;
}


.results_font {
    font-size: 23px;
}

.transition {
    transition: max-height 0.6s ease;
}
  
.faq-card {
    margin-bottom: 1rem;
    border-color: $blue;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.2);
    }
}

.faq-header {
    background-color: $blue;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: space-between;
}

.faq-header_landing {
    color: $blue;
    cursor: pointer;
    background-color: $white;
    padding: 9px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: space-between;
}

.faq-header.active {
    color: $blue;
    background-color: $white;
}
      
.tag {
    display: inline-block;
    padding: 5px;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 3px;
    color: rgb(0, 0, 0);
    font-size: 0.8em;
}

.blog-img {
    object-fit: cover;
    width: 100%;
    height: 25rem;
}

@media (max-width: 992px) {
    .blog-img {
        height: 20rem;
    }
}

@media (max-width: 768px) {
    .blog-img {
        height: 15rem;
    }
}

@media (max-width: 576px) {
    .blog-img {
        height: 10rem;
    }
}


.privacy-policy {
    font-family: 'Arial', sans-serif;
    color: #333;  /* dark grey color for better readability */
    line-height: 1.6; /* line spacing for readability */
    padding: 20px; /* padding around the text */
}
   
.privacy-policy h1,
.privacy-policy h2 {
    font-weight: bold; /* make headings bold */
    margin-top: 20px; /* add spacing above each heading */
}

.privacy-policy h1 {
    font-size: 24px; /* make main heading larger */
}

.privacy-policy h2 {
    font-size: 20px; /* slightly smaller sub-headings */
}

.privacy-policy p {
    margin-bottom: 20px; /* add space below paragraphs */
}

.privacy-policy ul {
    margin-left: 20px; /* indent lists */
    margin-bottom: 20px; /* add space below lists */
}
   
.mobile-menu-transition-enter {
    opacity: 0;
    transform: translateX(100%);
}

.mobile-menu-transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.mobile-menu-transition-exit {
    opacity: 1;
    transform: translateX(0);
}

.mobile-menu-transition-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
}

.faq-transition-enter {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 500ms ease-in-out;
}

.faq-transition-enter-active {
    opacity: 1;
    max-height: 500px;
}

.faq-transition-exit {
    opacity: 1;
    max-height: 500px;
}

.faq-transition-exit-active {
    opacity: 0;
    max-height: 0;
    transition: all 500ms ease-in-out;
}

@media only screen and (max-width: 600px) {
    #avatar {
        width: 180px;
        height: 180px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
    #avatar {
        width: 230px;
        height: 230px;
    }
}

@media only screen and (min-width: 1025px) {
    #avatar {
        width: 250px;
        height: 250px;
    }
}

.ratio {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ratio-content img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.carousel-control-prev,
.carousel-control-next {
    top: calc(50% - 20px); /* Adjust this value as needed */
}

.faq__content {
    display: grid;
    flex-wrap: wrap;
    justify-items: start;
    align-items: flex-start;
    margin: 0 auto;
    padding: 0 8vw;
}

@media screen and (min-width: 48rem) {
    .faq__content {
        display: grid;
        grid-column-gap: 0%;
        grid-template-columns: 28% 62%;
        align-items: center;
        justify-items: self-end;
    }
}
@media (max-width: 768px) {
    .faq__content {
        grid-template-columns: 100%;
    }
}

.faq_emphasized {
    transform: rotate(270deg);
    font-size: 170px;
    font-weight: 900;
    align-self: flex-start;
}

.faq_text {
    background: -webkit-linear-gradient(45deg, $black, $lightBlue, $black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;   
}

.legend-header {
    color: $blue; /* Bootstrap's primary color (light blue) */
    font-size: 1.25rem; /* Slightly larger font size for visibility */
    font-weight: 500; /* Medium font weight */
    margin-bottom: 1rem; /* Space between the header and the legend */
    text-align: center; /* Center alignment */
}

.footer_landing {
    background: $blue;
    color: $white;
}

.footer_link {
    text-decoration: none;
    color: $white;

    &:hover {
        color: $yellow; 
    }
}

.footer_separator {
    border-top: 4px solid $yellow;
    width: 100%;
    margin-top: 2rem;
}

.card-spacing {
    padding-left: 10px;
    padding-bottom: 10px;
}

.disabled-question {
    color: grey;
}

.row {
    --bs-gutter-x: 0;
}

.back_button {
    border: none;
    background-color: transparent;
    color: $blue;

    &:hover {
        font-weight: 700;
    }
}

.image-fluid {
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.custom-row {
    margin: -225px;
    padding: 0;
}


.landing-page {
    display: flex;
    flex-direction: column;
}


.desktop {
    display: none !important;

    @media (min-width: 768px) {
        display: block !important;
    }
}

.mobile {
    display: block !important;

    @media (min-width: 768px) {
        display: none !important;
    }
} 


.appointment-card {
    border: 1px solid #E3DBD5;
    background-color: #FFFFFF;
    border-radius: 10px;
    gap: 20px;
    padding: 24px;
    box-shadow: 0px 10px 20px #E9E2DD !important;
}

@media (max-width: 600px) {
    .appointment-card{
        gap: 2px;
        padding: 2px;
    }
}

.item-booked:hover {
    background-color: #b5e8c0;
    border-radius: 15px;
}

.item-passed:hover {
    background-color: #d9d9d9;
    border-radius: 15px;
}

.item-cancelled:hover {
    background-color: #fbb6b6;
    border-radius: 15px;
}


.therapist-image {
    border-radius: 10%;
    width: 170px;
    height: 180px; 
    object-fit: cover;
    box-shadow: 0px 0px 12px 5px rgb(147 149 153 / 15%);
}

.toggle-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
  }
  
.toggle-button {
    border: 2px solid #ccc; /* Light gray border */
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    background-color: #f8f9fa; /* Light background */
    color: #6c757d; /* Gray text */
    border-radius: 20px; /* Rounded corners for pill shape */
    font-weight: 500; /* Medium font weight */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for color and background */
    
    &:focus {
        outline: none;
    }
    &.active {
        background-color: #007bff; /* Bootstrap primary color */
        color: white; /* White text for active state */
    }
}

.custom_toast {
    position: fixed;
    bottom: 30px;
    left: 20px;
    z-index: 1050;
    display: flex;
    justify-content: center;
    padding: 7px;
}

.toast-success {
    background-color: rgba(76, 175, 80, 0.7);
}

.toast-error {
    background-color: rgba(244, 67, 54, 0.7);
}

.floating-warning {
    position: fixed;
    padding: 10px;
    background-color: $lightYellow; /* Semi-transparent yellow background */
    color: #333;
    border-radius: 5px;
    text-align: center;
    z-index: 1000; /* Ensure it floats above other content */
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Optional: Adds a subtle shadow for better visibility */

    span {
        margin-right: 5px;
    }

}

input:checked + .slider {
    background-color: #3CB371;
}

input:focus + .slider {
    box-shadow: 0 0 1px #3CB371;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.report-content {
    h1, h2, h3, h4, h5, h6 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
  
    p {
      margin-bottom: 1rem;
      line-height: 1.6;
    }
  
    ul, ol {
      margin-left: 1.5rem;
      margin-bottom: 1rem;
      
      li {
        margin-bottom: 0.5rem;
      }
    }
  
    strong {
      font-weight: 600;
    }
  
    em {
      font-style: italic;
    }
  }
