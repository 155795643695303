  $blue: #005eb1;
  $black: #000000;
  $yellow: #EEC230;
  $white: #ffffff;
  $grey: grey;
  $red: #aa4953;
  $lightBlue: #2e78ce;
  %btn-style {
    padding: 10px 24px;
    border-radius: 100px;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .sessions-infocard{
    margin-bottom: 30%;
    margin-left: 3%;
  }

  .sessions-info{
    color: #000000;
  }


  // .container-admin{
  //   margin-top: 1rem;
  //   margin-left: 4rem;
  //   margin-right: 4rem;
  //   @media (max-width: 600px) {
  //     margin-left: 1rem;
  //     margin-right: 1rem;
  //   }
  //   @media (min-width: 1900px) {
  //     margin-left: 6rem;
  //     margin-right: 6rem;
  //   }
  //   @media (min-width: 2200px) {
  //     margin-left: 9rem;
  //     margin-right: 9rem;
  //   }
  // }

  .apexcharts-legend{
    inset: auto 0px 0px !important;
    margin-top: 1% !important;
  }

  #apex-chart2{
    text-align: center;
    align-self: center;
  }

  #apex-chart1{
    text-align: center;
    align-self: center;
  }

  .admin-cardContent{
    padding: 42px 24px 36px 24px;
    gap: 32px;
  }

  #apexchartsi5hwbn1cg{
    width: 10rem;
  }



  .EnergyBoxes{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    gap: 1rem;
    @media (max-width: 475px) {
      gap: 0rem;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f192;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .text-desc{
    margin: 0 !important;
    @media (max-width: 600px) {
      margin-top: 4rem !important;
    }
  }

  .apex-card{
    margin-bottom: 1rem;
  }

  .dropdown-department{
    @media (max-width: 410px) {
      width: 7rem;
    }
  }

  .dropdown-department>button {
    @extend %btn-style;
    background-color: $blue;
    border: 1px solid $blue !important;
    color: $white;
    &:hover,
    &:focus {
        background-color: $blue;
        color: $white;
    }
    @media (max-width: 410px) {
      width: 100%;
      padding: 7px;
      font-size: 14px;
    }
  }

  .dropitem-department{
    border-radius: 10px;
    &:hover{
      background-color: rgba(0, 143, 251, 0.1) ;
      color: black;
    }
  }

  .dropdown-menu{
    border-radius: 10px;
  }

  [id^="apexcharts-radialbarTrack-"] {
    stroke: rgba(0, 0, 0, 0.092);
  }

  .chart-container {
    display: flex;
    flex-wrap: wrap;
  }

  .apex-chart {
    flex: 1;
    margin: 10px; 
    @media (max-width: 768px) {
      flex: 100%; 
    }
  }

  .to-filter{
    margin-left: 3.5rem;
    margin-right: 1.5rem;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem;
    font-size: 15px;
    font-weight: 500;
    gap: 2rem;
    @media (max-width: 768px) {
      font-size: 13px;
      margin-left: 0;
      margin-right: 0;
      font-weight: 500;
  }
  @media (max-width: 410px) {
    font-size: 11px;
  }
  }

  .colored-icon {
    transform: scale(1.3);
  }

  // foreignObject{
  //   height: 365px !important;
  // }

  // .apexcharts-svg{
  //   height: 380px !important;
  // }

  #mood-icon{
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(84, 84, 84);
  }

  .apexcharts-gridlines > line{
    stroke: transparent !important;
  }

  .apexcharts-gridline {
    stroke: transparent !important;
  }

  .apexcharts-grid-borders > line{
    stroke: transparent !important;

  }

  .apexcharts-xaxis-tick{
    stroke: transparent !important;
  }


  @media (max-width: 1200px) {
    @media (min-width: 768px) {
      #mood-icon{
        transform: scale(0.7);
        margin: 0;
      }
    }
  }
  @media (max-width: 500px) {
    #mood-icon{
      transform: scale(0.65);
      margin: -5px;
    }
    .colored-icon {
      transform: scale(0.85) !important;
    }
  }

  // .datepicker-admin{
  //   display: flex;
  //   margin-left: 1%;
  //   align-items: center;
  //   gap: 10px;
  // }

  .details-button{
    background-color: #005eb1;
    cursor: pointer;
  }

//   .react-datepicker__day--keyboard-selected{
//     background-color: #216ba5 !important;
//     color: white !important;
//   }

//   .datepicker-input {
//     max-width: 130px;
//     height: 38px;
//     border-radius: 10px;
//     padding: 8px;
//     font-size: 17px;
//     text-align: center;
//     border: none;
//     background-color: rgba(0, 143, 251, 0.1);
//     border: 1px #005eb1 solid;
//     box-shadow: 0 0.3rem 0.2rem rgba(19, 74, 255, 0.215);
//     &:focus {
//       outline: none;
//     }
//     &:hover{
//       cursor: pointer;
//     }
//     @media (max-width: 768px) {
//         width: 100px;
//         font-size: 15px;
//     }
//     @media (max-width: 590px) {
//       width: 55px !important;
//       font-size: 15px;
//     }
//     @media (max-width: 410px) {
//       width: 50px !important;
//       font-size: 13px;
//     }
//   }

//  .react-datepicker__month-container{
//   background-color: #e3ebf8 !important;
//   border-radius: 10px !important;
//  }
//  .react-datepicker__header {
//   background-color: #e6eaf3 !important;
//   border-radius: 10px !important;
//  }
//  .react-datepicker{
//   box-shadow: 0.2rem 0.4rem 0.4rem rgba(19, 74, 255, 0.215);
//   border-radius: 10px !important;
//  }

 .emotions-detail{
    background-color: rgb(0 143 251 / 10%);
    width: fit-content;
    padding: 15px;
    border-radius: 15px;
 }

 .admin-card{
  border-radius: 10px;
  border: 1px solid #E3DBD5;
  box-shadow: 0px 5px 10px #EFE5DE;
  background-color: #FFFFFF;
  
 }