.therapist-card {
    margin: 1em;
    background-color: #ffffff;
    border-radius: 10px !important;
    border: 1px solid #E3DBD5;
    box-shadow: 0px 31px 42px 0px #E9E2DD !important; 
    width: 400px;
    overflow: unset !important;
}

.therapist-card-img {
    object-fit: cover;
    width: 100%;
    height: 200px;
}

.rc-time-picker-panel-narrow{
    max-width: 50% !important;
}

.rc-time-picker-panel-inner{
    background-color: #5C83BF !important;
    width: 250px;
    height: 250px;
    overflow: auto;
}

.rc-time-picker-panel-select{
    max-height: 100% !important;
    font-size: 24px !important;
    width: 50% !important;
}

.rc-time-picker-panel-select ul{
    width: 50% !important;
}

.rc-time-picker-panel-select li{
    margin: 15px !important;
    border-radius: 2px;
    border: 0.7px solid #EDE4DF;
    padding: 5px 0px 28px 15px !important;
}

li.rc-time-picker-panel-select-option-selected{
    background-color: #62C294 !important;
}

.react-datepicker__input-container{
    text-align: center;
    display: none !important;
}
  
.react-datepicker__input-container > input {
    border-radius: 4px !important;
    font-size: 16px !important;
    padding: 4px 7px !important;
    color: #000000 !important;
    border: 1px solid #696969 !important;
    cursor: pointer;
}
  
.rc-time-picker-panel-input-wrap{
    display: none;
}
  
.rc-time-picker-panel-select li{
    color: white !important;
}
  
.rc-time-picker-panel-select li:hover{
    background-color: transparent ;
}
  
  
.rc-time-picker{
    text-align: center !important;
}
  
.rc-time-picker-input{
    font-size: 16px !important;
    width: auto !important;
    height: auto !important;
    color: #000000 !important;
    border: 1px solid #696969;
    cursor: pointer;
    display: none;
}

.react-datepicker__header {
    background-color: #5C83BF !important;
    border-radius: 10px !important;
    border-bottom: none !important;
    padding: 0 !important;
}

.react-datepicker__current-month{
    color: white !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    @media (max-width: 500px) {
        font-size: 18px !important;
      }
}

.react-datepicker__day{
  width: 3rem !important;
  padding: 6px !important;
  background-color: #62C294  !important;
  border: 0.7px solid #EDE4DF !important;
  margin: 1px !important;
  border-radius: 3px;
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  @media (max-width: 500px) {
    width: 2.2rem !important;
    padding: 3px !important;
  }
}

.react-datepicker__day-name{
  width: 2.8rem !important;
  padding: 6px !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  @media (max-width: 500px) {
    width: 2rem !important;
    padding: 4px !important;
  }
}

.react-datepicker__day--disabled{
  background-color: #5476AC !important;
  border: 0.7px solid #EDE4DF !important;
  opacity: 0.7 !important;
}

.react-datepicker__day--selected{
  background-color: #62C294 !important;
  border: 0.7px solid #EDE4DF !important;
  color: #2159b1 !important;
}

.react-datepicker__month-container{
background-color: #5C83BF !important;
border-radius: 8.5px !important;
padding: 10px !important;
}
.react-datepicker__navigation--next{
margin: 10px;
}

.react-datepicker__navigation--previous{
margin: 10px;
}

.react-datepicker{
    border-radius: 3% !important;
}