.calm-corner {
  position: relative;
  min-height: 100vh;
  
  &.playing {
    .background-blur {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(15px);
      z-index: 10;
      transition: backdrop-filter 0.8s ease;
    }
    
    .navbar, .footer, .content {
      z-index: 5;
    }
    
    .player-overlay {
      z-index: 20;
    }
  }
}

.lava-lamp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;
}

.blob-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.blob {
  position: absolute;
  border-radius: 50%;
  filter: blur(80px);
  mix-blend-mode: soft-light;
  opacity: 0.6;
  width: 250%;
  height: 250%;
  animation: moveBlob 240s infinite alternate ease-in-out, changeColor 180s infinite linear;
}

@keyframes moveBlob {
  0%, 100% { transform: translate(-30%, -30%) scale(1); }
  25% { transform: translate(20%, -20%) scale(1.1); }
  50% { transform: translate(30%, 30%) scale(1.2); }
  75% { transform: translate(-20%, 20%) scale(1.1); }
}

@keyframes changeColor {
  0%, 100% { background-color: #bdd1e0; }
  20% { background-color: #abc1d5; }
  40% { background-color: #96b0d0; }
  60% { background-color: #d6bc8a; }
  80% { background-color: #d0c1a4; }
}

.navbar, .footer {
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.7s ease;
  
  &.faded {
    opacity: 0.15;
    transform: scale(0.97);
    pointer-events: none;
  }
}

.content {
  position: relative;
  z-index: 1;
  transition: all 0.7s ease;
  padding: 2.5rem;
  
  &.faded {
    opacity: 0.15;
    transform: scale(0.97);
    pointer-events: none;
  }
}

.track-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2.5rem;
}

.track-card {
  position: relative;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 40px;
  overflow: hidden;
  transition: all 0.4s ease;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  
  &:hover {
    // slow the transition
    
    transform: scale(1.009);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
  
  .duration-bubble {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: rgba(255, 255, 255, 0.9);
    color: #5C83BF;
    padding: 0.6rem 1.2rem;
    border-radius: 2rem;
    font-size: 0.95rem;
    font-weight: bold;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease;
    

  }
}

.track-image {
  height: 220px;
  object-fit: cover;
  transition: transform 0.4s ease;
  
  &:hover {
    transform: scale(1.08);
  }
}

.track-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #567ab1;
}

.player-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
  opacity: 0;
  transform: scale(0.95);
  transition: all 0.7s ease;
  
  .calm-corner.playing & {
    opacity: 1;
    transform: scale(1);
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1000;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 5px;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}

.player-content {
  text-align: center;
  padding: 2.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 30px;
  backdrop-filter: blur(25px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}



#waveform {
  width: 350px;
  height: 350px;
  margin: 0 auto 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 40px rgba(92, 131, 191, 0.4);
  transition: all 0.7s ease;
  
  .calm-corner.playing & {
    animation: rotate 40s linear infinite, morph 20s ease-in-out infinite alternate;
  }
}

.track-name {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.player-controls {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  
  .MuiIconButton-root {
    background-color: rgba(92, 131, 191, 0.2);
    color: #5C83BF;
    transition: all 0.3s ease;
    
    &:hover {
      background-color: rgba(92, 131, 191, 0.3);
      transform: scale(1.1);
    }
  }
}
.MuiSlider-root {
  color: #96b0d0;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes morph {
  0%, 100% { border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%; }
  25% { border-radius: 40% 60% 70% 30% / 40% 70% 30% 60%; }
  50% { border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%; }
  75% { border-radius: 70% 30% 40% 60% / 30% 40% 70% 60%; }
}

@media (max-width: 768px) {
  .track-grid {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
  
  .player-content {
    padding: 2rem;
  }
  
  #waveform {
    width: 300px;
    height: 300px;
  }
  
  .track-name {
    font-size: 1.5rem;
  }
}