$blue: #005eb1;

.rmdp-wrapper {
    border: 1px $blue solid !important;
    border-radius: 10px !important;
    box-shadow: none !important;
}

.rmdp-input {
    height: 35px !important;
    width: 225px !important;
    border-radius: 10px !important;
}
.rmdp-header-values,
.rmdp-panel-header,
.rmdp-week,
.rmdp-time-picker {
    font-size: 17px; 
    font-weight: 700;
}

.rmdp-panel-body li {
    margin: 5px !important;
}

.b-date {
    font-weight: 700;
}

.rmdp-day span {
    border-radius: 8px !important;
}

.rmdp-panel-body li,
.rmdp-panel-body li .b-deselect {
    background-color: $blue !important;
}

.rmdp-day.rmdp-today span {
    background-color: #669eefa8 !important;
}