.navbar-dropdown{
    justify-content: flex-end;
    text-align: end;
    display: flex;
    align-items: center;
}

.home-appbar{
  padding-block: 24px;
}

@media (max-width: 800px) {
  .home-appbar{
    padding-block: 10px;
  }
}

.mypoka-link {
    background-color: #BED4F5;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 5px;
    color: #4c6481 !important;
  }

  .navbar-images{
    border-radius: 100%;
    padding: 3px;
    margin-right: 5px;
    width: 2rem;
    background-color: #BED4F5;
  }

  .navbar-nonimages{
    border-radius: 100%;
    padding: 5px;
    margin-right: 5px;
    width: 2.3rem;
    background-color: #0e2d2f34;
  }

  .navbar-home{
    background-color: #BED4F5;
    border-radius: 100%;
    margin-right: 5px;
    width: 1.5rem;
    padding: 3px;
    height: 1.5rem;
  }

  .navbar-nonhome{
    background-color: #0e2d2f34;
    border-radius: 100%;
    padding: 6px;
    margin-right: 5px;
    width: 1.5rem;
    height: 1.5rem;
  }



.vertical-line {
    height: 60%;
    width: 1px;
    background-color: #ffffff; /* Adjust the color as needed */
    margin: 0 10px; /* Adjust the spacing */
  }
  

.mobile_menu {
  display: inline;
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px 0px;
  width: 45%;
  height: 100%;
  background: #5C83BF;
  z-index: 1000;
  box-shadow: 0 0 18px 4px rgba(26, 98, 185, 0.8);
  animation: slide-in 0.3s forwards;
  -webkit-animation: slide-in 0.3s forwards;
}

.hamburger-menu_links {
  color: #FFF7F1;
  text-decoration: none;
  padding: 15px 10px;
}  

.app_bar {
  padding-block: 24px;
}
@media (max-width: 800px) {
  .app_bar {
    padding-top: 20px;
  }
}