.wellness-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF7F1;
  overflow: hidden;

  .loader-background {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  .background-circle {
    position: absolute;
    border-radius: 50%;
    transform-origin: center;
    transition: all 4s cubic-bezier(0.4, 0, 0.2, 1);
    
    &.circle-1 {
      width: 100vh;
      height: 100vh;
      background: radial-gradient(
        circle,
        rgba(76, 110, 162, 0.08) 0%,
        rgba(165, 187, 213, 0.08) 50%,
        rgba(255, 247, 241, 0) 100%
      );
    }
    
    &.circle-2 {
      width: 90vh;
      height: 90vh;
      background: radial-gradient(
        circle,
        rgba(76, 110, 162, 0.06) 0%,
        rgba(165, 187, 213, 0.06) 50%,
        rgba(255, 247, 241, 0) 100%
      );
    }
    
    &.circle-3 {
      width: 80vh;
      height: 80vh;
      background: radial-gradient(
        circle,
        rgba(76, 110, 162, 0.04) 0%,
        rgba(165, 187, 213, 0.04) 50%,
        rgba(255, 247, 241, 0) 100%
      );
    }

    &.expand {
      transform: scale(1.2);
    }

    &.contract {
      transform: scale(1);
    }
  }

  .loader-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    padding: 3rem;
    max-width: 480px;
    width: 100%;
    z-index: 1;
  }

  .progress-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
  }

  .progress-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    .progress-background {
      position: absolute;
      color: rgba(76, 110, 162, 0.12);
    }

    .progress-indicator {
      position: absolute;
      color: #4C6EA2;
      transition: all 0.3s ease-out;
    }
  }

  .message-container {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .loading-message {
    color: #4C6EA2;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    position: absolute;
    width: 100%;
    max-width: 400px;
    padding: 0 20px;
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);

    &.fade-out {
      opacity: 0;
      transform: translateY(10px);
    }

    &.fade-in {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .progress-text {
    color: #5C83BF;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }

  .breathing-section {
    margin: 1rem 0;
  }

  .breathing-guide {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .breathing-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid rgba(76, 110, 162, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: rgba(76, 110, 162, 0.05);
    backdrop-filter: blur(4px);
    
    .breath-text-wrapper {
      position: relative;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .breath-text {
      color: #4C6EA2;
      font-size: 0.75rem;
      font-weight: 500;
      text-transform: lowercase;
      letter-spacing: 0.5px;
      position: absolute;
      transition: all 2s cubic-bezier(0.4, 0, 0.2, 1);
      white-space: nowrap;

      &.text-fade-in {
        opacity: 1;
        transform: translateY(0);
      }

      &.text-fade-out {
        opacity: 0.8;
        transform: translateY(0);
      }
    }

    .breath-ripple {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      border: 2px solid rgba(76, 110, 162, 0.2);
      border-radius: 50%;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    &.breathe-in {
      background: rgba(76, 110, 162, 0.08);
      border-color: rgba(76, 110, 162, 0.4);
    }

    &.breathe-out {
      background: rgba(76, 110, 162, 0.05);
    }
  }

  .breath-count {
    // padding top
    padding: 1rem 0;
    color: #5C83BF;
    font-size: 0.75rem;
    opacity: 0.7;
  }

  .instruction-text {
    color: #8D8885;
    font-size: 0.875rem;
    line-height: 1.6;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    opacity: 0.9;
  }

  @keyframes rippleExpand {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0;
    }
  }

  @keyframes rippleContract {
    0% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }
  }

  @media (max-width: 480px) {
    .loader-content {
      padding: 2rem 1.5rem;
    }

    .message-container {
      height: 60px;
    }

    .loading-message {
      font-size: 1.25rem;
    }

    .instruction-text {
      font-size: 0.8125rem;
    }

    .background-circle {
      &.circle-1 { width: 150vw; height: 150vw; }
      &.circle-2 { width: 130vw; height: 130vw; }
      &.circle-3 { width: 110vw; height: 110vw; }
    }
  }

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
}