.fade-in-out {
    animation: fadeInOutAnimation 2s ease-in-out;
  }
  
  .fade-out-in {
    animation: fadeOutInAnimation 2s ease-in-out;
  }
  
  @keyframes fadeInOutAnimation {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
  }
  
  @keyframes fadeOutInAnimation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
  }
